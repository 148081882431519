import Vue from 'vue';
/**
 * Third party libs
 */
window.$ = jQuery;

Vue.prototype.$eventHub = new Vue();
$('[vue-app]').each((_, el) => {
  // eslint-disable-next-line no-new
  new Vue({
    el,
  });
});
