// slick
import 'slick-carousel';
import MicroModal from 'micromodal';
import './plugins/gravity-form';

$('[data-slick]').slick();

$(() => {
  // Micromodal
  MicroModal.init({
    disableScroll: true,
    onClose: () => {
      $('body').css('overflow', '');
    }
  });

  // membership
  $('.button-membership').click(function (event) {
    event.preventDefault();
    $('.accordion').toggle();
  });
});
