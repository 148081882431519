/* global gformAddListItem, gform */
import 'select2';
import 'select2/dist/css/select2.min.css';

function initGForm() {
  // Select2
  let counter = 0;
  const prefix = 'gform-select2-';
  const selector = '.gform_body select';

  const generateUniqueID = () => {
    counter += 1;
    return `${prefix}${counter}`;
  };

  const initSelect2 = () => {
    $(selector).each(function () {
      const $this = $(this);
      if (!$this.attr('id')) {
        $this.attr('id', generateUniqueID());
      }
      $this.select2({
        width: 'style'
      });
    });
  };

  const destroySelect2 = () => {
    $(selector).each(function () {
      const $this = $(this);
      if ($this.data('select2')) {
        $this.select2('destroy');
      }
    });
  };

  initSelect2();
  // Remove the default event handler & plug in our event
  // Due to select2, we need to destroy & reinit them again after cloning
  $('.add_list_item')
    .removeAttr('onclick')
    .removeAttr('onkeypress');

  $('.gfield_list_container').on('click onkeypress', '.add_list_item', function () {
    destroySelect2();
    gformAddListItem(this, 10);
    initSelect2();
  });

  gform.addAction('gform_list_post_item_add', $clone => {
    // Remove all cached select2 attributes
    $clone.find('[data-select-2]').attr('id', null);
    $clone.find('[data-select2-id]').attr('data-select2-id', null);
  });

  $('.gfield_list_container').on('change', '[data-select-2]', function () {
    const $this = $(this);
    const $input = $this.siblings('[data-bound-select2]');
    const values = $this.val() || [];
    $input.val(values.join(';'));
  });

  // Add instruction text for the form
  $('#gform_1 .gfield_list_icons').append('<span class="help">Click on (+) to add more Nominees.</span>');
}

if (window.gform !== undefined) {
  initGForm();
}

jQuery(document).ready(function ($) {
  const isConfirmation = $('#gform_confirmation_wrapper_1').length > 0;

  if (isConfirmation) {
    $('.become-member-section, .membership-types-section').hide();
  }
});
