import Vue from 'vue';
import VModal from 'vue-js-modal';

// Popup
Vue.use(VModal, {
  dialog: true
});

// Components
Vue.component('tribe-event-tickets', require('./components/tribe-event-tickets.vue').default);
Vue.component('normal-member', require('./components/normal-member.vue').default);
