<template>
  <div v-if="tickets.length > 0">
    <form
      id="tpp-buy-tickets"
      :action="action"
      class="tribe-tickets-tpp cart tribe-tpp-message-display"
      method="post"
      enctype="multipart/form-data"
      @submit="submit"
    >
      <div>
        <div class="tribe-tpp-messages">
          <div
            v-for="message in messages"
            :key="message.message"
            :class="`tribe-tpp-message tribe-tpp-message-${message.type}`"
          >
            {{ message.message }}
          </div>
        </div>

        <h4 v-if="!isSoldOut">Purchaser Details</h4>
        <table v-if="!isSoldOut" floaclass="gfield_list gfield_list_container attendee-details" width="100%">
          <tbody>
            <tr class="gfield_list_row_odd gfield_list_group">
              <div class="attendee-info">
                <div>
                  <input
                    v-validate="'required'"
                    type="hidden"
                    data-vv-as="Title"
                    name="purchaser[title]"
                    :value="model['purchaser[title]']"
                  />
                  <multiselect
                    v-model="model['purchaser[title]']"
                    aria-label="Title"
                    type="text"
                    placeholder="Title"
                    :class="{ error: errors.has('purchaser[title]') }"
                    :options="titles"
                  />
                  <p>{{ errors.first('purchaser[title]') }}</p>
                </div>
                <div>
                  <input
                    v-model="model['purchaser[first_name]']"
                    v-validate="'required'"
                    aria-label="First Name"
                    data-vv-as="First Name"
                    type="text"
                    placeholder="First Name"
                    name="purchaser[first_name]"
                    :class="{ error: errors.has('purchaser[first_name]') }"
                  />
                  <p>{{ errors.first('purchaser[first_name]') }}</p>
                </div>
                <div>
                  <input
                    v-model="model['purchaser[last_name]']"
                    v-validate="'required'"
                    aria-label="Last Name"
                    data-vv-as="Last Name"
                    type="text"
                    placeholder="Last Name"
                    name="purchaser[last_name]"
                    :class="{ error: errors.has('purchaser[last_name]') }"
                  />
                  <p>{{ errors.first('purchaser[last_name]') }}</p>
                </div>
                <div>
                  <input
                    v-model="model['purchaser[phone]']"
                    aria-label="Mobile Phone Number"
                    data-vv-as="Mobile Phone Number"
                    type="text"
                    placeholder="Mobile Phone Number"
                    name="purchaser[phone]"
                    :class="{ error: errors.has('purchaser[phone]') }"
                  />
                </div>
                <div>
                  <input
                    v-model="model['purchaser[email]']"
                    v-validate="'required|email'"
                    aria-label="Email"
                    data-vv-as="Email"
                    type="email"
                    placeholder="Email"
                    name="purchaser[email]"
                    :class="{ error: errors.has('purchaser[email]') }"
                  />
                  <p>{{ errors.first('purchaser[email]') }}</p>
                </div>
                <div>
                  <input
                    v-model="model['purchaser[designation]']"
                    v-validate="'required'"
                    aria-label="Job Title"
                    data-vv-as="Job Title"
                    type="text"
                    placeholder="Job Title"
                    name="purchaser[designation]"
                    :class="{ error: errors.has('purchaser[designation]') }"
                  />
                  <p>{{ errors.first('purchaser[designation]') }}</p>
                </div>
                <div>
                  <input
                    v-model="model['purchaser[company]']"
                    v-validate="'required'"
                    aria-label="Company"
                    data-vv-as="Company"
                    type="text"
                    placeholder="Company"
                    name="purchaser[company]"
                    :class="{ error: errors.has('purchaser[company]') }"
                  />
                  <p>{{ errors.first('purchaser[company]') }}</p>
                </div>
                <div class="membership-dropdown">
                  <input
                    v-validate="'required'"
                    type="hidden"
                    data-vv-as="Membership"
                    name="purchaser[membership]"
                    :value="model['purchaser[membership]']"
                  />
                  <multiselect
                    v-model="model['purchaser[membership]']"
                    aria-label="Membership"
                    type="text"
                    placeholder="Select Your Partner Chamber"
                    :class="{ error: errors.has('purchaser[membership]') }"
                    :options="membership"
                    :multiple="true"
                  />
                  <p>{{ errors.first('purchaser[membership]') }}</p>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="tribe-events-tickets-title tribe--tpp">Tickets</h2>

      <div class="tribe-events-tickets tribe-events-tickets-tpp">
        <select v-model="selectedTicket">
          <option value>Please select</option>
          <option v-for="ticket in tickets" :key="ticket.id" :value="ticket">{{ ticket.name }}</option>
        </select>

        <div v-if="selectedTicket">
          <table>
            <tbody>
              <tr>
                <td v-if="!isFreeTicket" class="tickets_name">Price per ticket</td>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <td v-if="!isFreeTicket" class="tickets_price" v-html="selectedTicket.price_html" />
                <td class="tickets_name">Quantity</td>
                <td v-if="isSoldOut">
                  <label class="sold-out-label">Sold Out</label>
                </td>
                <td v-else class="tribe-ticket" :data-product-id="selectedTicket.ID">
                  <input type="hidden" name="add" value="1" />
                  <input type="hidden" name="provider" :value="selectedTicket.provider" />
                  <input type="hidden" name="product_id[]" :value="selectedTicket.ID" />
                  <input
                    v-model.number="amount"
                    v-validate
                    type="number"
                    class="tribe-ticket-quantity qty"
                    data-vv-as="Quantity"
                    min="1"
                    :max="selectedTicket.inventory !== -1 ? selectedTicket.inventory : false"
                    :name="`quantity_${selectedTicket.ID}`"
                    required
                  />
                  <p>{{ errors.first(`quantity_${selectedTicket.ID}`) }}</p>
                </td>

                <td class="tickets_description" colspan="2">{{ selectedTicket.description }}</td>
                <td v-if="!isSoldOut">
                  <div>
                    <h4>Attendee Details</h4>
                    <div><input v-model="isSamePurchase" type="checkbox" /> Same as above</div>
                    <table class="gfield_list gfield_list_container attendee-details" width="100%">
                      <tbody>
                        <tr v-for="index in amount" :key="index" class="gfield_list_row_odd gfield_list_group">
                          <div class="attendee-info">
                            <div>
                              <input
                                v-validate="'required'"
                                type="hidden"
                                data-vv-as="Title"
                                :name="`attendees[${index}][title]`"
                                :value="model[`attendees[${index}][title]`]"
                              />
                              <multiselect
                                v-model="model[`attendees[${index}][title]`]"
                                aria-label="Title"
                                type="text"
                                placeholder="Title"
                                :class="{ error: errors.has(`attendees[${index}][title]`) }"
                                :options="titles"
                              />
                              <p>{{ errors.first(`attendees[${index}][title]`) }}</p>
                            </div>
                            <div>
                              <input
                                v-model="model[`attendees[${index}][first_name]`]"
                                v-validate="'required'"
                                aria-label="First Name"
                                data-vv-as="First Name"
                                type="text"
                                placeholder="First Name"
                                :name="`attendees[${index}][first_name]`"
                                :class="{ error: errors.has(`attendees[${index}][first_name]`) }"
                              />
                              <p>{{ errors.first(`attendees[${index}][first_name]`) }}</p>
                            </div>
                            <div>
                              <input
                                v-model="model[`attendees[${index}][last_name]`]"
                                v-validate="'required'"
                                aria-label="Last Name"
                                data-vv-as="Last Name"
                                type="text"
                                placeholder="Last Name"
                                :name="`attendees[${index}][last_name]`"
                                :class="{ error: errors.has(`attendees[${index}][last_name]`) }"
                              />
                              <p>{{ errors.first(`attendees[${index}][last_name]`) }}</p>
                            </div>
                            <div>
                              <input
                                v-model="model[`attendees[${index}][phone]`]"
                                aria-label="Mobile Phone Number"
                                data-vv-as="Mobile Phone Number"
                                type="text"
                                placeholder="Mobile Phone Number"
                                :name="`attendees[${index}][phone]`"
                              />
                              <p>{{ errors.first(`attendees[${index}][phone]`) }}</p>
                            </div>
                            <div>
                              <input
                                v-model="model[`attendees[${index}][email]`]"
                                v-validate="'required|email'"
                                aria-label="Email"
                                data-vv-as="Email"
                                type="email"
                                placeholder="Email"
                                :name="`attendees[${index}][email]`"
                                :class="{ error: errors.has(`attendees[${index}][email]`) }"
                              />
                              <p>{{ errors.first(`attendees[${index}][email]`) }}</p>
                            </div>
                            <div>
                              <input
                                v-model="model[`attendees[${index}][designation]`]"
                                v-validate="'required'"
                                aria-label="Job Title"
                                data-vv-as="Job Title"
                                type="text"
                                placeholder="Job Title"
                                :name="`attendees[${index}][designation]`"
                                :class="{ error: errors.has(`attendees[${index}][designation]`) }"
                              />
                              <p>{{ errors.first(`attendees[${index}][designation]`) }}</p>
                            </div>
                            <div>
                              <input
                                v-model="model[`attendees[${index}][company]`]"
                                v-validate="'required'"
                                aria-label="Company"
                                data-vv-as="Company"
                                type="text"
                                placeholder="Company"
                                :name="`attendees[${index}][company]`"
                                :class="{ error: errors.has(`attendees[${index}][company]`) }"
                              />
                              <p>{{ errors.first(`attendees[${index}][company]`) }}</p>
                            </div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td v-if="!isSoldOut">
                  <div v-if="!isFreeTicket">
                    <div>
                      <h4>Payment Method</h4>
                      <select v-model="model['payment-method']" name="payment-method" class="payment">
                        <option value>Please select</option>
                        <option value="paypal">PayPal</option>
                        <option value="offline-payment">Offline Payment</option>
                      </select>
                      <p>{{ errors.first('payment-method') }}</p>
                    </div>
                    <div v-if="model['payment-method']" class="tickets_submit">
                      <button type="submit" class="tpp-submit tribe-button" :disabled="submitting">Register Now</button>
                    </div>
                  </div>

                  <div v-if="isFreeTicket" class="tickets_submit">
                    <button type="submit" class="tpp-submit tribe-button" :disabled="submitting">Register Now</button>
                  </div>
                </td>
              </tr>

              <slot name="after_ticket_row" />
            </tbody>
          </table>
        </div>

        <div class="tickets_submit"></div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect';

Vue.use(VeeValidate);

export default {
  components: {
    Multiselect,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      submitting: false,
      selectedTicket: '',
      amount: 1,
      titles: ['Mr', 'Master', 'Mrs', 'Miss', 'Ms', 'Dr', 'Prof'],
      membership: [
        'Non Member',
        'EuroCham',
        'Dutch Chamber of Commerce (Singapore) (DutchCham)',
        'Belgium-Luxembourg Chamber of Commerce (BLCC)',
        'Central Eastern European Chamber in Singapore (CEEC)',
        'Danish Business Association in Singapore (DABS)',
        'French Chamber of Commerce in Singapore (FCCS)',
        'Irish Chamber of Commerce (Singapore)',
        'Italian Chamber of Commerce in Singapore (ICCS)',
        'Norwegian Business Association of Singapore (NBAS)',
        'Singaporean-German Chamber of Industry and Commerce (SGC)',
        'Spanish-Singaporean Chamber of Commerce (SpanishCham)',
        'Swedish Chamber of Commerce, Singapore (SwedCham)',
        'Swiss Chamber of Commerce and Industry, Singapore (SwissCham)',
      ],
      model: {
        'payment-method': '',
      },
      isSamePurchase: false,
    };
  },

  computed: {
    isFreeTicket() {
      if (!this.selectedTicket) {
        return false;
      }
      return parseFloat(this.selectedTicket.price) === 0;
    },
    isSoldOut() {
      return this.selectedTicket.inventory === 0;
    },
  },

  watch: {
    isSamePurchase(val) {
      if (val) {
        this.model['attendees[1][title]'] = this.model['purchaser[title]'];
        this.model['attendees[1][first_name]'] = this.model['purchaser[first_name]'];
        this.model['attendees[1][last_name]'] = this.model['purchaser[last_name]'];
        this.model['attendees[1][phone]'] = this.model['purchaser[phone]'];
        this.model['attendees[1][email]'] = this.model['purchaser[email]'];
        this.model['attendees[1][designation]'] = this.model['purchaser[designation]'];
        this.model['attendees[1][company]'] = this.model['purchaser[company]'];
      }
    },
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      if (await this.$validator.validate()) {
        this.submitting = true;
        e.target.submit();
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.attendee-details {
  border: none;

  tr {
    display: table-row;
  }
}

.attendee-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 800px) {
    display: block;
  }

  .membership-dropdown {
    flex-basis: 66%;
  }

  > * {
    flex-basis: 33%;
    margin: 5px 0 !important;

    input {
      width: 100%;
    }
  }

  ::v-deep {
    .multiselect {
      &__tags {
        padding-top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        &-wrap {
          display: flex;
        }
      }

      &__single,
      &__placeholder {
        font: normal 17px / 26px 'Open Sans', Helvetica, Arial, Verdana, sans-serif;
      }

      &__single {
        color: #8b8d94;
        margin-bottom: 0;
      }

      &__input {
        border: 0;
      }

      &__placeholder {
        margin-bottom: 0;
        padding-top: 0;
        color: rgba(#8b8d94, 0.5);
      }
    }

    ul {
      margin: 0;
    }
  }
}

.attendee-info .error {
  border: 1px solid red;
}

.tribe-tpp-messages {
  padding-left: 0;

  .tribe-tpp-message {
    font-size: 1rem;
    padding: 5px;
    background: orange;
  }
}

.multiselect {
  padding-bottom: 10px;
}

.sold-out-label {
  color: #464646;
}

.tribe-events-tickets select {
  background: url(/wp-content/uploads/2020/03/arrow_drop_down_grey_24x24.png);
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 99%;
  width: 67%;
}

.payment {
  width: 33% !important;
}

@media only screen and (max-width: 800px) {
  .tribe-events-tickets select,
  .payment {
    width: 100% !important;
  }
}
</style>
