<template>
  <div class="normal-member member-list">
    <div class="member-list__heading">
      <h3>MEMBERS</h3>
      <div class="member-list__slider-arrows">
        <i class="fa fa-angle-left" @click="prev"></i>
        <i class="fa fa-angle-right" @click="next"></i>
      </div>
    </div>

    <!-- Sliders  -->
    <div ref="slick" class="member-list__slider">
      <div v-if="!members.length" class="member-list__slider-item" />
      <div v-for="member in members" :key="member.id" class="member-list__slider-item">
        <img :src="member.logo_src" alt :srcset="member.logo_src" @click="showModal(member)" />
      </div>
    </div>

    <!-- Modal -->
    <modal
      :name="`${currentMember.id}`"
      scrollable
      adaptive
      width="100%"
      height="100%"
      :max-width="900"
      :max-height="468"
    >
      <!-- <div class="modal__container"> -->
      <div class="normal-member__column-popup">
        <div class="normal-member__column-popup-inner-img">
          <div class="text-center-hidden">
            <i aria-label="Close modal" class="fa fa-times" @click="hideModal"></i>
          </div>
          <div class="gold-member__image-popup normal-member__image">
            <img
              class="img-popup"
              :src="currentMember.logo_src"
              alt
              :srcset="currentMember.logo_src"
            />
          </div>
          <div class="normal-member__name">
            <p>{{ currentMember.name }}</p>
          </div>
          <div class="normal-member__website">
            <a
              target="_blank"
              :href="'https://' + currentMember.website"
            >{{ currentMember.website }}</a>
          </div>
          <div class="normal-member__text normal-member__overflow-img">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="currentMember.address" />
          </div>
          <div class="normal-member__name">
            <p>{{ currentMember.phone_number }}</p>
          </div>
          <div class="normal-member__column-popup-inner-text-hidden">
            <div class="normal-member__text normal-member__overflow">{{ currentMember.summary }}</div>
          </div>
        </div>

        <div class="normal-member__column-popup-inner-text">
          <div class="text-center">
            <i aria-label="Close modal" class="fa fa-times" @click="hideModal"></i>
          </div>
          <div class="normal-member__text normal-member__overflow">{{ currentMember.summary }}</div>
        </div>
      </div>
      <!-- </div> -->
    </modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    members: [],
    currentMember: {
      id: '',
      logo_src: '',
      name: '',
      website: '',
      address: '',
      phone_number: '',
      summary: '',
    },
  }),

  created() {
    /* eslint-disable no-undef */
    $.post(dtLocal.ajaxurl, {
      action: 'members_fetch',
    }).then(res => {
      this.members = res.data.members;

      this.$nextTick(() => {
        $(this.$refs.slick).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: false,
        });
      });
    });
  },

  methods: {
    showModal(member) {
      this.currentMember = member;
      this.$nextTick(() => {
        this.$modal.show(`${this.currentMember.id}`);
      });
    },

    hideModal() {
      this.$modal.hide(`${this.currentMember.id}`);
    },

    next() {
      $(this.$refs.slick).slick('slickNext');
    },

    prev() {
      $(this.$refs.slick).slick('slickPrev');
    },
  },
};
</script>
